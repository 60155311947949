var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isVisible)?_c('div',{staticClass:"model-floatCheck mf_03"},[_c('a',{staticClass:"floatCheck-Closebtn close-bg-live"}),_c('div',{staticClass:"floatCheck-container"},[_vm._m(0),_c('div',{staticClass:"floatCheck-container-main-live"},[_c('div',{staticClass:"live-floatCheck-container-box"},[_c('div',{staticClass:"live-product-info-container clearfix"},[_c('div',{staticClass:"product-coupon-container-cart clearfix"},[_c('div',{staticClass:"product-coupon-title"},[_c('p',{staticClass:"fn-md spacing left sm"},[_vm._v(" 神腦生活價"),_c('span',{staticClass:"price spacing fn-lg"},[_vm._v(_vm._s(_vm.mart.salePrice.toLocaleString()))]),_vm._v("元 ")])]),_c('div',{staticClass:"product-coupon-table spacing top sm"},[_c('div',{staticClass:"css-table viewcoupon middle fn-sm"},[_vm._m(1),_vm._l((_vm.coupons),function(coupon,couponIndex){return _c('div',{key:couponIndex,class:[
                    'css-tr',
                    { staff: !_vm.isHasCouponId(coupon) },
                    { 'coupon-later-use': coupon.value === 'later' },
                  ]},[(_vm.isHasCouponId(coupon))?[_c('div',{staticClass:"css-td text-left"},[_c('span',{staticClass:"date"},[_c('div',{staticClass:"cp_unicode"},[_vm._v("$")]),_c('div',{staticClass:"cp_text"},[_vm._v(_vm._s(coupon.itemCoupon.toLocaleString()))])]),_c('span',{staticClass:"cp_text1"},[_vm._v(" "+_vm._s(coupon.isAmountLimit === 'Y' ? `適用商品滿$${coupon.threshold}元可用` : '指定商品適用')+" ")]),_c('span',{staticClass:"cp_text2"},[_vm._v(_vm._s(coupon.couponTitle))]),_c('span',{staticClass:"cp_date"},[_vm._v(" "+_vm._s(coupon.value === 'later' ? `生效時間${coupon.startDate}` : `有效期限${coupon.endDate}`)+" ")])]),_c('div',{staticClass:"css-td text-center fn-sm w-6 price"},[_vm._v(_vm._s(_vm.formatDollar(coupon.discountPrice)))])]:[_c('div',{staticClass:"css-td text-left"},[_c('div',{staticClass:"date"},[_c('div',{staticClass:"cp_staff"},[_vm._v(_vm._s(coupon.couponDesc))])])]),_c('div',{staticClass:"css-td text-center fn-sm w-6 price"},[_vm._v(_vm._s(_vm.formatDollar(_vm.mart.empPrice)))])],_c('div',{staticClass:"css-td text-center"},[(coupon.value === 'later')?[_vm._v(" 稍後使用 ")]:(coupon.value === 'used')?[_c('span',{staticClass:"notice"},[_vm._v("(已使用)")])]:[_c('div',{staticClass:"checkbox"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.couponId),expression:"couponId"}],attrs:{"type":"radio","name":"coupon"},domProps:{"value":coupon.value,"checked":_vm._q(_vm.couponId,coupon.value)},on:{"change":function($event){_vm.couponId=coupon.value}}})])]],2)],2)})],2)])])])])]),_c('div',{staticClass:"cart-check-bar fixed bottom"},[_c('div',{staticClass:"btn-group_product"},[_c('a',{staticClass:"btn btn-primary-outline btn-round",attrs:{"href":"javascript:void(0);"},on:{"click":_vm.cancelHandler}},[_vm._v("不使用")]),_c('a',{staticClass:"btn btn-primary btn-round",attrs:{"href":"javascript:void(0);"},on:{"click":_vm.onPricingChange}},[_vm._v("確定")])])])])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"float_t-text"},[_c('b',[_vm._v("請選擇優惠方案 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"css-tr"},[_c('div',{staticClass:"css-th text-left"},[_vm._v("優惠方案")]),_c('div',{staticClass:"css-th text-center w-f5"},[_vm._v("折後金額")]),_c('div',{staticClass:"css-th text-right w-f5"})])
}]

export { render, staticRenderFns }