<template>
  <div class="total">
    <div class="title">金額計算</div>
    <div class="total-item">
      <span class="label">小計</span>
      <span class="price">{{ subTotal }}</span>
    </div>
    <div class="total-item" v-if="hasPoint">
      <span class="label">神腦幣</span>
      <span class="price">-{{ pointMoney }}</span>
    </div>
    <div class="total-item amount">
      <span class="label">商品總金額</span>
      <span class="price">{{ amount }}</span>
    </div>
  </div>
</template>

<script>
const props = {
  // 是否有神腦幣
  hasPoint: Boolean,
  subTotal: String,
  pointMoney: String,
  amount: String,
};

export default {
  name: 'TotalAmount',
  props,
};
</script>
