<template>
  <div class="container">
    <header-cart>
      <template slot="header-middle">進行驗證序號</template>
    </header-cart>
    <main>
      <div class="notice-block-container">
        <div class="spec-content eden_box">
          <h3 class="title eden_title">
            請輸入神腦的Edenred即享券序號，
            <br />
            進行驗證序號
          </h3>
          <div class="edenred">
            <label>SED</label>
            <input
              class="eden_input"
              type="text"
              maxlength="12"
              placeholder="請輸入 SED 後12碼數字"
              v-model.trim="setSerial" />
          </div>
          <div class="edenred-error" v-if="edenredIsError">
            {{ edenredErrorTxt }}
          </div>
          <div class="edenred-point">
            可用餘額
            <br />
            <b class="price">{{ edenredBalanceTotal | dollar }}</b>
          </div>
          <div class="btn-group">
            <a class="btn btn-primary-outline btn-round" href="javascript:void(0);" @click="close"> 回購物車 </a>
            <a
              class="btn btn-primary btn-round"
              href="javascript:void(0);"
              @click="checkSerialNumberAndFetchEdenred(setSerial, isOpenLoadingState)">
              驗證序號
            </a>
          </div>
          <ol class="eden_list">
            <li class="edenred-notice">
              Edenred 即享券需先驗證序號，確認餘額，若驗證有誤請依錯誤說明，與相關單位確認問題再繼續進行結帳。
            </li>
            <li class="edenred-notice">同一筆訂單，可驗證多張序號，將依序號驗證順序進行折抵訂單金額。</li>
          </ol>
        </div>
        <div class="spec-content">
          <h4 class="title eden_title">
            預計於本次交易進行抵用序號
            <br />
            共{{ getEdenredTotalCount }}筆序號，合計可用餘額
            <b class="price">{{ edenredBalanceTotal | dollar }}</b>
          </h4>
          <table class="eden_tb">
            <tr>
              <th class="eden_td_01">驗證順序</th>
              <th>已驗證序號</th>
              <th class="eden_td_02">可用餘額</th>
              <th></th>
            </tr>
            <tr v-for="(item, index) in getEdenredGroup" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ item.serial }}</td>
              <td>{{ item.balance }}</td>
              <td>
                <a class="btn btn-default btn-sm spacing" @click="deleteThisEdenred(item)"> 刪除 </a>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import { getState, renderState, commitMutation, commitMutations } from '@/helpers/vuex';
import { dollar } from '@/helpers/format/dollar';
import {
  getEdenredGroup,
  checkSerialNumberAndFetchEdenred,
  getEdenredTotalCount,
  edenredBalanceTotal,
  isUserNotOwnedEdenredForShowText,
  deleteThisEdenred,
} from '@/helpers/edenred';
import headerCart from '@/components/mobile/Header/header-cart.vue';

const components = {
  headerCart,
};

const close = function () {
  commitMutations('CartDetail', {
    isFetchEdenredHasBalance: false,
    edenredIsError: false,
    edenredErrorTxt: '',
    isCheckUsedEdenred: false,
  });
  commitMutation('DynamicComponents', 'cartMain', 'default-view');
};

export default {
  name: 'edenred-modal',
  components,
  data() {
    return {
      setSerial: '',
    };
  },
  filters: {
    dollar,
  },
  computed: {
    edenredIsError: getState('CartDetail', 'edenredIsError'),
    edenredErrorTxt: getState('CartDetail', 'edenredErrorTxt'),
    getEdenredGroup,
    getEdenredTotalCount,
    edenredBalanceTotal,
    isUserNotOwnedEdenredForShowText,
    isOpenLoadingState() {
      return renderState('Loading', 'isLoading');
    },
  },
  methods: {
    close,
    checkSerialNumberAndFetchEdenred,
    deleteThisEdenred,
  },
};
</script>
