<template>
  <div class="container">
    <CouponModal :isVisible="isShowCouponModal" :mart="currentCart" @on-pricing-change="handlePriceChange" />
    <header-cart>
      <template slot="header-middle">購物車明細</template>
    </header-cart>
    <main class="gray" style="min-height: calc(100vh - 70px)">
      <div>
        <!-- Line提示-->
        <LineTip v-if="isLinePoints" />
        <!-- 商品總計-->
        <div class="notice-bar">
          共
          <b class="spacing">{{ getCartDetailCountFilterGift }}</b>
          項商品
        </div>
        <!-- 商品列表-->
        <div class="list cart-list">
          <div class="item product" v-for="(item, index) in getCartDetail" :key="`cart-${cartType}-${index}`">
            <div class="row">
              <a v-if="hasLink(item.mbLink)" class="img-sm" :href="`${baseUrl}${item.mbLink}`">
                <img class="img-sm" :src="item.masterImage" alt="商品名稱" />
              </a>
              <img v-else class="img-sm" :src="item.masterImage" alt="商品名稱" />
              <div class="info">
                <div class="row">
                  <div class="desc">
                    <CartTag :item="item"></CartTag>
                    <h2 class="title" v-if="hasLink(item.mbLink)">
                      <a :href="`${baseUrl}${item.mbLink}`">{{ item.productName }}</a>
                    </h2>
                    <h2 class="title" v-else>
                      {{ item.productName }}
                    </h2>
                  </div>
                  <div class="btn-group" v-if="isFavorOrDelete(item)">
                    <a
                      class="btn-link"
                      href="javascript:void(0);"
                      v-if="isFavor(item.isFavor)"
                      @click="collectProducts(item)">
                      <i class="icon icon-add-heart-outline"></i>
                    </a>
                    <a
                      class="btn-link"
                      href="javascript:void(0);"
                      v-if="isDelete(item.isDelete)"
                      @click="deleteProducts(item)">
                      <i class="icon icon-delete"></i>
                    </a>
                  </div>
                </div>
                <div class="row">
                  <span class="type">
                    規格
                    <b class="spacing left sm">{{ item.productSpec }}</b>
                  </span>
                  <span>
                    售價
                    <b class="spacing left sm">{{ item.finalPrice | dollar }}</b>
                  </span>
                  <span class="tag tag-profit spacing left mini" v-if="isShowDiscountTag(item)"> 折扣價 </span>
                </div>
                <div class="row">
                  <div class="number-group" v-if="isMajorItem(item.cartTag)">
                    <button class="number-less" @click="changeQty(item, 'reduce')">
                      <i class="icon icon-minus"></i>
                    </button>
                    <input
                      class="number-view"
                      type="number"
                      :value="item.productQty"
                      @change="customQty($event, item)"
                      :max="item.limitQuantity"
                      min="1" />
                    <button class="number-plus" @click="changeQty(item, 'increase')">
                      <i class="icon icon-plus"></i>
                    </button>
                  </div>
                  <span class="qty" v-else>
                    數量
                    <b class="spacing left sm">{{ item.productQty }}</b>
                  </span>
                  <span>
                    小計
                    <b class="price spacing left sm">{{ getSubTotal(item) | dollar }}</b>
                  </span>
                </div>
                <div class="row" v-if="item.packQuantity > 1">
                  <div class="notice spacing top">此商品為 {{ item.packQuantity }} 件組</div>
                </div>
                <div class="row">
                  <!-- 週期購 -->
                  <cycle-order :is-mobile="true" :item="item" :item-index="index"></cycle-order>
                  <!-- 優惠方案 -->
                  <template v-if="isMajorItem(item.cartTag) && isShowPricingPlans(item)">
                    <div class="product-option spacing top">
                      <a class="arr om_03" @click="chooseCoupons(item)">
                        {{ item.couponDesc ? item.couponDesc : '選擇使用優惠方案' }}
                      </a>
                    </div>
                    <div class="notice spacing top mini"><small>折扣﹑優惠僅可擇一使用</small></div>
                  </template>
                </div>
              </div>
            </div>
            <cycle-order-content :is-mobile="true" :product-qty="item.productQty" :item="item"></cycle-order-content>
            <ul class="product-event" v-if="isShowDiscount(item)">
              <li>
                <span class="tag tag-profit" v-bind:class="isActiveDiscount(item)"> 折扣活動 </span>
                <span class="label">{{ item.discountName }}</span>
              </li>
            </ul>
          </div>
        </div>
        <!-- 神腦幣 & hamipoint & Edenred -->
        <CheckBonus
          :format-point-money="formatPointMoney | dollar"
          :point-money="pointMoney | dollar"
          :point-max-exchange-rate="pointMaxExchangeRate"
          :is-auth-hami-point="isAuthHamiPoint"
          :hami-point="hamiPoint"
          :is-show-hami-point-block="isShowHamiPointBlock"></CheckBonus>
        <!-- 應付總金額-->
        <TotalAmount
          :hasPoint="hasPoint"
          :point-money="pointMoney | dollar"
          :subTotal="subTotal | dollar"
          :amount="amount | dollar"></TotalAmount>
        <!-- 配送方式-->
        <DeliveryType />
        <!-- 配送提示-->
        <div class="text-bar">
          <div class="notice">
            於疫情期間可能延遲配送時效，未配達前請您耐心等侯，詳情請見公告(
            <a class="notice underline" href="https://event.senao.com.tw/senaomessage_20210525001/"> 配送作業調整 </a>
            )。
          </div>
        </div>
        <!-- 運費提示: 限宅配車預設不需運費 -->
        <ShipTip
          v-if="showShipFare"
          :shipFare="getShipFare.shipFare"
          :shipFareFreeLimit="getShipFare.shipFareFreeLimit" />
        <login-modal @check-out="checkOut()"></login-modal>
        <div class="cookie-notice text-center" v-if="utm">
          {{ utm }}
        </div>
      </div>
    </main>
    <!-- 置底按鈕-->
    <BottomBtn
      :cartCount="getCartDetailCountFilterGift"
      :get-cart-detail="getCartDetail"
      :amount="amount | dollar"
      :cartMainSubmitBtnDisabled="this.cartMainSubmitBtnDisabled"
      @check-out="checkOut()"></BottomBtn>
  </div>
</template>

<script>
import { renderState, callMutation, commitMutations } from '@/helpers/vuex';
import { dollar, NumberWithPoint } from '@/helpers/format/dollar';
import { cartMainMountedMixin } from '@/mixins/cart/main/mounted';
import { cartMainPartial } from '@/mixins/cart/main/partial';
import BottomBtn from '@/components/mobile/main/bottom-btn.vue';
import CheckBonus from '@/components/mobile/main/check-bonus.vue';
import DeliveryType from '@/components/mobile/main/delivery-type.vue';
import headerCart from '@/components/mobile/Header/header-cart.vue';
import LineTip from '@/components/mobile/main/line-tip.vue';
import ShipTip from '@/components/mobile/main/ship-tip.vue';
import TotalAmount from '@/components/mobile/main/total-amount.vue';
import CycleOrder from '@/components/common/cycle-order.vue';
import CycleOrderContent from '@/components/common/cycle-order-content.vue';
import LoginModal from '@/components/common/modals/login-modal.vue';
import CouponModal from '@/components/mobile/main/coupon-modal.vue';
import CartTag from '@/components/mobile/main/cart-tag.vue';

// 是否顯示 LinePoint 提示 */
const isLinePoints = () => renderState('CartDetail', 'isLinePoints');

// 判斷折扣活動的 className for Mobile
const isActiveDiscount = () => (item) => item.selectPricingPlan ? 'tag-default' : 'tag-secondary';

const utm = () => renderState('CartDetail', 'utm');

const computed = {
  isLinePoints,
  isActiveDiscount,
  utm,
};

const methods = {
  // 個別商品小計
  getSubTotal(item) {
    return Math.max(item.finalPrice * item.productQty - item.couponDiscount, 0);
  },
  chooseCoupons(item) {
    this.currentCart = { ...item };
    this.isShowCouponModal = true;
  },
  handlePriceChange(couponId) {
    this.isShowCouponModal = false;
    const cartIndex = this.getCartDetail.findIndex((cart) => cart.detailId === this.currentCart.detailId);
    const payload = {
      couponId,
      cartIndex,
    };
    this.onPricingChange(payload);
  },
};

const filters = {
  dollar,
  NumberWithPoint,
};

const data = function () {
  return {
    cartType: this.$route.query.type,
    currentCart: {},
    isShowCouponModal: false,
  };
};

export default {
  name: 'DefaultCartMain',
  mixins: [cartMainMountedMixin, cartMainPartial], // 同桌機
  components: {
    headerCart,
    LineTip,
    CheckBonus,
    TotalAmount,
    DeliveryType,
    ShipTip,
    BottomBtn,
    CycleOrder,
    CycleOrderContent,
    LoginModal,
    CouponModal,
    CartTag,
  },
  methods,
  computed,
  data,
  filters,
  mounted() {
    const isCalledStoreAPI = renderState('CityTownStores', 'isCalledStoreAPI');
    if (isCalledStoreAPI) {
      // 有呼叫過 storeAPI 即不 reset
      return;
    }
    callMutation('RecipientInfo', 'RESET_STATE');
    commitMutations('CityTownStores', {
      cityIndex: 0,
      townIndex: 0,
      storeIndex: 0,
    });
  },
};
</script>

<style scoped>
.tag-event-outline {
  color: #f61a13;
  border: 1px solid #f61a13;
}
.item.product .qty b {
  vertical-align: middle;
}
</style>
