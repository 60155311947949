<!-- 新的modal樣式, 無法套用共用modal元件 -->
<template>
  <div v-if="isVisible" class="model-floatCheck mf_03">
    <a class="floatCheck-Closebtn close-bg-live"></a>
    <div class="floatCheck-container">
      <div class="float_t-text"><b>請選擇優惠方案 </b></div>
      <div class="floatCheck-container-main-live">
        <div class="live-floatCheck-container-box">
          <div class="live-product-info-container clearfix">
            <div class="product-coupon-container-cart clearfix">
              <div class="product-coupon-title">
                <p class="fn-md spacing left sm">
                  神腦生活價<span class="price spacing fn-lg">{{ mart.salePrice.toLocaleString() }}</span
                  >元
                </p>
              </div>
              <div class="product-coupon-table spacing top sm">
                <div class="css-table viewcoupon middle fn-sm">
                  <div class="css-tr">
                    <div class="css-th text-left">優惠方案</div>
                    <div class="css-th text-center w-f5">折後金額</div>
                    <div class="css-th text-right w-f5"></div>
                  </div>
                  <div
                    :class="[
                      'css-tr',
                      { staff: !isHasCouponId(coupon) },
                      { 'coupon-later-use': coupon.value === 'later' },
                    ]"
                    v-for="(coupon, couponIndex) in coupons"
                    :key="couponIndex">
                    <!-- 優惠券(才有couponId) -->
                    <template v-if="isHasCouponId(coupon)">
                      <div class="css-td text-left">
                        <span class="date">
                          <div class="cp_unicode">$</div>
                          <div class="cp_text">{{ coupon.itemCoupon.toLocaleString() }}</div>
                        </span>
                        <span class="cp_text1">
                          {{ coupon.isAmountLimit === 'Y' ? `適用商品滿$${coupon.threshold}元可用` : '指定商品適用' }}
                        </span>
                        <span class="cp_text2">{{ coupon.couponTitle }}</span>
                        <span class="cp_date">
                          {{ coupon.value === 'later' ? `生效時間${coupon.startDate}` : `有效期限${coupon.endDate}` }}
                        </span>
                      </div>
                      <div class="css-td text-center fn-sm w-6 price">{{ formatDollar(coupon.discountPrice) }}</div>
                    </template>
                    <!-- 員購價 -->
                    <template v-else>
                      <div class="css-td text-left">
                        <div class="date">
                          <div class="cp_staff">{{ coupon.couponDesc }}</div>
                        </div>
                      </div>
                      <div class="css-td text-center fn-sm w-6 price">{{ formatDollar(mart.empPrice) }}</div>
                    </template>
                    <div class="css-td text-center">
                      <template v-if="coupon.value === 'later'"> 稍後使用 </template>
                      <template v-else-if="coupon.value === 'used'">
                        <span class="notice">(已使用)</span>
                      </template>
                      <template v-else>
                        <div class="checkbox">
                          <input type="radio" name="coupon" :value="coupon.value" v-model="couponId" />
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="cart-check-bar fixed bottom">
        <div class="btn-group_product">
          <a class="btn btn-primary-outline btn-round" href="javascript:void(0);" @click="cancelHandler">不使用</a
          ><a class="btn btn-primary btn-round" href="javascript:void(0);" @click="onPricingChange">確定</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { watch } from 'vue';
import { useCoupon } from '@/composables/coupon';

export default {
  name: 'CouponModal',
  props: {
    mart: Object,
    isVisible: Boolean,
  },
  setup(props, target) {
    const { onPricingChange, cancelHandler, isHasCouponId, coupons, filterCoupon, formatDollar, couponId } = useCoupon(
      props,
      target
    );

    watch(
      () => props.mart,
      (newMart) => {
        // 顯示user 上一輪選的值
        couponId.value = '';
        if (newMart.couponId) {
          couponId.value = newMart.couponId;
        }
        coupons.value = filterCoupon(newMart);
      }
    );

    return {
      onPricingChange,
      cancelHandler,
      isHasCouponId,
      coupons,
      filterCoupon,
      formatDollar,
      couponId,
    };
  },
};
</script>
<style scoped>
/* fix: 因原有mockup需調用多次coupon.value === 'later'判斷 */
.coupon-later-use div,
.coupon-later-use span {
  color: #8d9194;
}
</style>
