<template>
  <div v-if="!isMajorOrDiscountItem" :class="cartTagDisplay[item.cartTag]?.style">
    {{ cartTagDisplay[item.cartTag]?.text }}
  </div>
</template>
<script>
import { computed } from 'vue';

export default {
  name: 'CartTag',
  props: {
    item: Object,
  },
  setup(props) {
    const MAJOR_ITEM = '0';
    const DISCOUNT_ITEM = '1';
    const isMajorOrDiscountItem = computed(
      () => props.item.cartTag === MAJOR_ITEM || props.item.cartTag === DISCOUNT_ITEM
    );
    const defaultStyle = 'tag';
    const cartTagDisplay = {
      // 主商品沒有tag
      // 活動折扣tag不同於PC, 獨立放在其他位置
      2: {
        text: '加購品',
        style: defaultStyle.concat(' ', 'tag-secondary-outline'),
      },
      3: {
        text: '贈品',
        style: defaultStyle.concat(' ', 'tag-extra-outline'),
      },
      4: {
        text: '組合品',
        style: defaultStyle.concat(' ', 'tag-primary-outline'),
      },
    };
    return {
      cartTagDisplay,
      isMajorOrDiscountItem,
    };
  },
};
</script>
