<template>
  <p class="text-right text-muted divider fn-sm">
    本島運費：購物滿
    <b class="price spacing">${{ shipFareFreeLimit }}</b>
    元，免收運費
    <b class="price spacing">${{ shipFare }}</b>
    元
    <br />
    <span>(實付金額：需扣除優惠券、神腦幣)</span>
  </p>
</template>

<script>
const props = {
  shipFare: Number,
  shipFareFreeLimit: Number,
};

export default {
  name: 'ShipTip',
  props,
};
</script>
