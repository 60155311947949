<template>
  <component :is="dynamicComponent" :key="dynamicComponent"></component>
</template>

<script>
import { getState } from '@/helpers/vuex';
import DefaultView from '@/components/mobile/main/default-view.vue';
import MobileEdenredModal from '@/components/mobile/main/edenred-modal.vue';

export default {
  name: 'MobileCartMain',
  components: {
    DefaultView,
    MobileEdenredModal,
  },
  computed: {
    dynamicComponent: getState('DynamicComponents', 'cartMain'),
  },
};
</script>
