<template>
  <div class="text-bar" v-if="isShowDelivery">
    <span class="fn-md spacing right">請選擇配送方式</span>
    <template v-for="(item, index) in deliveries">
      <label class="radio-inline" :key="index">
        <input v-model="delivery" type="radio" name="delivery-type" :value="item.delivery" />
        {{ item.deliveryName }}
      </label>
    </template>
  </div>
</template>

<script>
import { gt, compose, prop, __ } from 'ramda';
import { getState, createDynamicComponent } from '@/helpers/vuex';
import { componentLut } from '@/components/mobile/step1/constant';

/** 當 [配送方式] > 1 時，才會顯示 [提供選擇配送方式] */
const isShowDelivery = compose(gt(__, 1), prop('length'), getState('DeliveryInfo', 'deliveries'));

const computed = {
  /** Load 所有 [配送方式] */
  deliveries: getState('DeliveryInfo', 'deliveries'),
  /** user 選擇的配送方式 or 電子票券預設為 '0'，for checkOut API 判斷用 */
  /** 所選擇 [配送方式] */
  delivery: createDynamicComponent('DeliveryInfo', 'delivery', 'deliveryMethod', componentLut),
  /** 當 [配送方式] > 1 時，才會顯示 [提供選擇配送方式] */
  isShowDelivery,
};

export default {
  name: 'DeliveryType',
  computed,
};
</script>
