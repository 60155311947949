<template>
  <!-- Line購物，提示-->
  <div class="notice-bar">
    <h4 class="line-cart-notice">
      <img class="pull-left spacing sm right" src="/images/line-points-icon.png" />
      {{ Text_1 }}
    </h4>
    <div class="clearfix">
      <p>{{ Text_2 }}</p>
      <p class="text-muted spacing mini top">{{ Text_3 }}</p>
    </div>
  </div>
</template>

<script>
import lang from '@/lang/tw.json';

const data = () => ({
  Text_1: lang.cart.lineTip_1,
  Text_2: lang.cart.lineTip_2,
  Text_3: lang.cart.lineTip_3,
});

export default {
  name: 'LineTip',
  data,
};
</script>
