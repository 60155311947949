<template>
  <div class="fieldset">
    <div class="check-table">
      <!-- 紅利 -->
      <div class="check-cell" v-if="hasPoint">
        <div class="checkbox">
          <input type="checkbox" id="bonus" v-model="checkPoint" />
        </div>
        <div class="text">
          <label for="bonus" style="color: #1b1c1d">
            神腦幣
            <span class="notice fn-mini">
              最高可折抵單筆結帳金額{{ pointMaxExchangeRate }}%
              <br />
            </span>
            <br />
            <span class="text-muted">可折抵</span>
            <b class="price">{{ formatPointMoney }}</b>
          </label>
        </div>
      </div>
      <!-- Hamipoint: 已登入且非R身份且 isFromApp !== 2 者才能顯示-->
      <div class="check-cell" v-if="isShowHamiPointBlock">
        <div class="checkbox" v-if="isAuthHamiPoint">
          <input type="checkbox" :disabled="true" checked />
        </div>
        <div class="text" v-if="isAuthHamiPoint">
          <span class="text-muted">
            您已完成中華會員身分驗證，
            <span class="notice">
              可於【結帳】時，使用Hami Point 點數折抵 (可用 Hami Point 點數：{{ hamiPoint | NumberWithPoint }} 點)
            </span>
          </span>
        </div>
        <!-- 未驗證時顯示 -->
        <div class="checkbox" v-if="!isAuthHamiPoint">
          <input type="checkbox" id="hamipoint" v-model="isCheckAuthHamiPoint" @change="openModal('HamipointModal')" />
        </div>
        <div class="text" v-if="!isAuthHamiPoint">
          <label for="hamipoint">
            <span class="text-muted">
              結帳使用
              <b>Hami Point 點數</b>
              折抵 (1點可折抵1元)
              <br />
              將前往中華電信驗證會員資料。
              <br />
              <b class="notice" v-if="isShowChtLoginMsg"> 馬上驗證中華會員身份，完成購物即享 HamiPoint 贈點回饋。 </b>
            </span>
          </label>
        </div>
      </div>
      <div class="check-cell">
        <div class="checkbox" :class="[isUserNotOwnedEdenredForShowText ? 'EdenVerify' : '']">
          <input
            id="edenred"
            type="checkbox"
            name="checkEdenred"
            v-model="isCheckUsedEdenred"
            :disabled="!isUserNotOwnedEdenredForShowText"
            @change="openFullScreenEdenred" />
        </div>
        <label class="text" for="edenred">
          <span class="text-muted">結帳時，使用 Edenred 即享券</span>
          <div>
            <span class="text-muted" v-if="isUserNotOwnedEdenredForShowText"> 將前往驗證 Edenred 即享券券序號 </span>
            <span class="text-muted" v-else>
              已完成驗證，序號{{ formatEdenredSerialText }}，
              <br />
              共{{ getEdenredTotalCount }}筆，合計可用餘額
              <b class="price">{{ edenredBalanceTotal | dollar }}</b>
              <a class="underline spacing" href="javascript:void(0);" @click="openFullScreenEdenred"> 更換序號 </a>
            </span>
          </div>
        </label>
      </div>
    </div>
    <auth-hamipoint-alert-modal @cancel="isCheckAuthHamiPoint = false" />
  </div>
</template>

<script>
import { commitMutation, renderState, getGetter, getState, commitMutations } from '@/helpers/vuex';
import { NumberWithPoint, dollar } from '@/helpers/format/dollar';
import {
  getEdenredTotalCount,
  formatEdenredSerialText,
  edenredBalanceTotal,
  isUserNotOwnedEdenredForShowText,
} from '@/helpers/edenred';
import { hasPoint } from '@/mixins/cart/main/partial';
import { openModal } from '@/helpers/modal';
import AuthHamipointAlertModal from '@/components/common/modals/hamipoint-modal.vue';

// 是否選用神腦幣的 checkbox 並計算神腦幣折抵金額
const checkPoint = {
  get() {
    return renderState('CartDetail', 'isCheckPoint');
  },
  set(value) {
    commitMutation('CartDetail', 'isCheckPoint', value);
  },
};

const isCheckUsedEdenred = {
  get() {
    return renderState('CartDetail', 'isCheckUsedEdenred');
  },
  set(value) {
    commitMutation('CartDetail', 'isCheckUsedEdenred', value);
  },
};

const props = {
  formatPointMoney: String,
  pointMoney: String,
  pointMaxExchangeRate: Number,
  isAuthHamiPoint: Boolean,
  hamiPoint: Number,
  isShowHamiPointBlock: Boolean,
};

const filters = {
  NumberWithPoint,
  dollar,
};

export default {
  name: 'CheckBonus',
  filters,
  props,
  components: {
    AuthHamipointAlertModal,
  },
  data() {
    return {
      isCheckAuthHamiPoint: false,
    };
  },
  computed: {
    checkPoint,
    getCartDetail: getGetter('CartDetail', 'getterCartDetail'),
    isShowChtLoginMsg: getState('CartDetail', 'isShowChtLoginMsg'),
    isCheckUsedEdenred,
    hasPoint,
    isUserNotOwnedEdenredForShowText,
    formatEdenredSerialText,
    edenredBalanceTotal,
    getEdenredTotalCount,
  },
  methods: {
    openModal,
    openFullScreenEdenred() {
      commitMutations('CartDetail', {
        isCheckUsedEdenred: true,
      });
      commitMutation('DynamicComponents', 'cartMain', 'mobile-edenred-modal');
    },
  },
};
</script>
