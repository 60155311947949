<template>
  <div class="cart-check-bar fixed bottom">
    <div class="btn-group">
      <a class="btn btn-primary-outline btn-round" href="javascript:void(0);" @click="goBackFromFlag"> 繼續購物 </a>
      <button
        type="button"
        class="btn btn-primary btn-round"
        :disabled="this.cartMainSubmitBtnDisabled"
        @click="checkOut">
        結帳
      </button>
    </div>
    <div class="inner-total">
      <div class="title">
        共
        <b class="spacing sm">{{ cartCount }}</b>
        項商品
      </div>
      <span class="label">合計</span>
      <span class="price">{{ amount }}</span>
    </div>
  </div>
</template>

<script>
import { dollar } from '@/helpers/format/dollar';
import { goBackFromFlag } from '@/helpers/navigation-goback-where';

const checkOut = function () {
  this.$emit('check-out');
};

const methods = {
  goBackFromFlag,
  checkOut,
};

const props = {
  // 購物車裡商品數量
  cartCount: Number,
  getCartDetail: Array,
  amount: String,
  cartMainSubmitBtnDisabled: Boolean,
};

const filters = {
  dollar,
};

export default {
  name: 'BottomBtn',
  props,
  methods,
  filters,
};
</script>
